<template>
    <modal ref="modalGestionarFaqs" :titulo="tituloModal" icon="faq" @guardar="ejecutarAccion">
        <div class="row m-3 f-15">
            <div class="col">
                <label class="ml-2" v-text="textoSeleccionado.titulo" />
                <el-input v-model="payload.titulo" maxlength="80" show-word-limit class="pr-input" />
            </div>
        </div>
        <div class="row m-3 f-15">
            <div class="col">
                <label class="ml-2" v-text="textoSeleccionado.texto" />
                <el-input v-model="payload.texto" type="textarea" :rows="3" maxlength="1000" show-word-limit class="pr-input" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'ModalGestionarFaqs',
    props: {
        tipo: {
            type: Number,
            default: 1
        },
        modoEdicion: {
            type: Boolean,
            default: false
        },
        datos: {
            type: Object,
            default: () => ({})
        }
    },
    data(){
        return {
            payload: { texto: '', titulo: '' },
            textos: {
                1: {
                    titulo: 'Pregunta',
                    texto: 'Respuesta'
                },
                2: {
                    titulo: 'Término',
                    texto: 'Explicación'
                },
                3: {
                    titulo: 'Política',
                    texto: 'Explicación'
                }
            },
            accionSegunModoEdicion: {
                true: 'actualizar',
                false: 'crear'
            }
        }
    },
    computed: {
        textoSeleccionado(){
            return this.textos[this.tipo]
        },
        tituloModal(){
            const sufijo = this.textoSeleccionado.titulo.toLowerCase()
            return this.modoEdicion ? `Editar ${sufijo}` : `Crear ${sufijo}`
        }
    },
    watch: {
        datos(valores){
            if (!_.isEmpty(valores) && this.modoEdicion) this.copiarDatos(this.datos)
        }
    },
    methods: {
        toggle(){
            this.limpiarPayload()
            this.$refs.modalGestionarFaqs.toggle()
        },
        limpiarPayload(){
            this.payload = { texto: '', titulo: '' }
        },
        copiarDatos({ titulo, texto }){
            this.payload = { texto, titulo }
        },
        ejecutarAccion(){
            this.$emit(this.accionSegunModoEdicion[this.modoEdicion], this.payload)
            this.toggle()
        }
    }
}
</script>
